<template>
  <div>
    <section class="container-fluid">
      <v-row class="d-flex justify-center justify-md-space-around align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column justify-center align-center border__image"
        >
          <h3>O que é o projeto ?</h3>

          <p>
            O Mariana Território Atingido consubstancia parte do trabalho
            desenvolvido durante o

            <router-link
              tag="a"
              style="text-decoration: none"
              class="pink--text"
              :to="{ name: 'sobre_cadastro' }"
              >Cadastramento</router-link
            >
            dos Atingidos e Atingidas pelo Rompimento da Barragem de Fundão em
            Mariana, projeto que foi finalizado pela Cáritas em abril de 2022.
            Surge dos anseios da equipe de que os 1.503 dossiês familiares
            produzidos e as reflexões desenvolvidas nesse intenso processo de
            trabalho levantando multiplicidade de perdas e danos causados às
            comunidades atingidas no território Rio Gualaxo do Norte pudessem
            ser mais acessíveis ao público em geral. Criar esse site foi a forma
            que encontramos para constituir como mais uma ferramenta de apoio à
            luta das pessoas atingidas, tendo sempre como orientação a pauta da
            <router-link
              tag="a"
              style="text-decoration: none"
              class="pink--text"
              :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
              >reparação justa e integral</router-link
            >.
          </p>
          <p>
            Apresentamos aqui os
            <router-link
              tag="a"
              style="text-decoration: none"
              class="pink--text"
              :to="{ name: 'mapa_comunitario' }"
              >mapas comunitários</router-link
            >, construídos a partir das centenas de
            <router-link
              tag="a"
              style="text-decoration: none"
              class="pink--text"
              :to="{ name: 'sobre_cartografia_social_familiar' }"
              >cartografias sociais familiares</router-link
            >
            realizadas junto aos atingidos sobrepostos aos dados de
            <router-link
              tag="a"
              style="text-decoration: none"
              class="pink--text"
              :to="{ name: 'sobre_vistoria_advi' }"
              >geoprocessamento</router-link
            >
            construídos a partir dos atendimentos. Esses mapas trazem a riqueza
            dos territórios e comunidades atingidas, em um formato que visa
            imbricar as representações espaço-afetivas traçadas pelos sujeitos
            centrais de nosso trabalho, os atingidos, às técnicas de informações
            georreferenciadas. Há disponíveis três camadas de visualização:
            Recursos Hídricos, Referências Comunitárias e Usos e Ocupações.
            Também é possível escolher sobre qual temporalidade observar os
            mapas: antes ou depois do rompimento. Esta navegação auxilia a
            compreender a transformação do território e o impacto socioambiental
            causado pelo rompimento.
          </p>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center justify-md-end">
          <v-img max-width="80%" src="../assets/sobre_projeto_01.png" alt="" />
        </v-col>
      </v-row>
    </section>
    <section class="container-fluid">
      <v-row
        class="d-flex justify-center justify-md-space-around mt-5 mb-5 flex-md-row-reverse"
      >
        <v-col
          cols="10"
          md="7"
          class="border__image d-flex justify-center align-center"
        >
          <p>
            Ao percorrer os mapas, há ícones georreferenciados que trazem
            conteúdos hipermídia sobre o território. Essas páginas visam
            <span class="pink--text"
              >caracterizar as comunidades atingidas</span
            >
            cadastradas pela Cáritas em Mariana. Descrever cuidadosamente cada
            uma permite superar a dicotomia de comunidades direta e
            indiretamente atingidas. A passagem da
            <span class="pink--text">lama tóxica</span> dentro do terreno do
            atingido não é marcador significante e único para diferenciá-los,
            visto que a lama causou extremos danos e alterou profundamente a
            dinâmica social, cultural e econômica local mesmo sem
            necessariamente ter alcançado cotas de todas áreas em questão. A
            extensão territorial onde se encontram as comunidades atingidas do
            município de Mariana teve sua formação há mais de 300 anos. Para
            entender os danos e o que não está sendo restituído, é fundamental a
            compreensão de como essas comunidades foram formadas e viveram ao
            longo dos séculos até o rompimento, bem como as relações que se
            estabeleciam entre elas, as quais foram profundamente
            desestruturadas pelo desastre criminoso e as demoras do processo de
            reparação.
          </p>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center justify-md-start">
          <v-img max-width="80%" src="../assets/sobre_projeto_02.png" alt="" />
        </v-col>
      </v-row>
    </section>
    <section class="container-fluid">
      <v-row
        class="d-flex justify-center justify-md-space-around justify-center mt-5 mb-5"
      >
        <v-col
          cols="10"
          md="7"
          class="border__image d-flex flex-column justify-center align-center"
        >
          <p>
            Produzimos ainda materiais curtos sobre temas diversos, como criança
            e adolescente, gênero, águas, cultura alimentar, moradia,
            discriminação, tempos, entre outros. Pretendemos, assim, qualificar
            o debate sobre desigualdades, racismo ambiental e injustiça social
            com os dados produzidos durante o cadastramento. O rompimento
            atravessou as vidas dessas comunidades e mostramos como ainda as
            afeta. Contudo não quisemos apresentar um território determinado a
            partir do desastre criminoso, mas sim uma exposição dos elementos
            que perduram mesmo após a desterritorialização e a urbanização
            forçada de muitos atingidos. Tratamos aqui das manifestações
            culturais, do patrimônio, dos modos de vida, dos ofícios e modos de
            fazer, toda a riqueza e pluralidade dessas comunidades.
          </p>
          <p>
            Depreendemos grande esforço para transformar esses dados em
            conteúdos dispostos para a não revitimização desses sujeitos, mas
            ainda assim acessíveis e informativos.
          </p>
          <p><strong>Esperamos que gostem! </strong></p>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center justify-md-end">
          <v-img max-width="80%" src="../assets/sobre_projeto_03.png" alt="" />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h3 {
  font-size: 2em;
}

@media (max-width: 600px) {
  .border__image {
    max-width: 100% !important;
    padding: 0;
    background-color: white;
  }
  .border__image::before {
    display: none;
  }
}

section {
  margin-bottom: 5em;
}

.border__image {
  max-width: 40%;
  padding: 2em 3em 2.5em 2.5em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../assets/sobre_border_image.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.7em;
  max-width: 100%;
  z-index: 20;
}
.border__image h3 {
  color: #e06919;
}
</style>
